import React from 'react'
import { ContentBlockColumnListItemFragment } from '../src/model/fragments'
import { BarelyVisibleTitle } from './BarelyVisibleTitle'

export interface NumberedColumnsProps {
	title?: string
	items: ContentBlockColumnListItemFragment[]
}

export const NumberedColumns: React.FunctionComponent<NumberedColumnsProps> = ({
	title,
	items,
}) => {
	return (
		<div className="numberedColumns">
			{title && (
				<div className="numberedColumns-title">
					<BarelyVisibleTitle level={2}>{title}</BarelyVisibleTitle>
				</div>
			)}
			<div className="numberedColumns-items">
				{items.map((item, i) => (
					<div key={i} className="numberedColumns-item" dangerouslySetInnerHTML={{ __html: item.html }}>
					</div>
				))}
			</div>
		</div>
	)
}
