import React from 'react'
import striptags from 'striptags';

export interface GardenProps {
	title: string
}

export const Garden: React.FunctionComponent<GardenProps> = ({
	title,
	children,
}) => {
	const t = React.useMemo(() => {
		return striptags(title).split("\\n").join('<br/>').split("\n").join("<br />");
	}, [title]);

	return (
		<section className="garden">
			<h2 className="garden-title" dangerouslySetInnerHTML={{__html: t}} />
			<div className="garden-content">{children}</div>
		</section>
	)
}
