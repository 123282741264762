import React from 'react'
import { usePageLink } from '../src/hooks/usePageLink'
import { ContentBlockColumnListItemFragment, ContentBlockColumnListItemLinkType, SpecialPageType } from '../src/model/fragments'
import { getLinkWithQuery } from '../src/model/urls'
import { BarelyVisibleTitle } from './BarelyVisibleTitle'
import { CardBox } from './CardBox'
import { FlexiLinkProps } from './FlexiLink'

export interface LinkBoxesProps {
	title?: string
	items: ContentBlockColumnListItemFragment[]
}

export const LinkBoxes: React.FunctionComponent<LinkBoxesProps> = ({
	title,
	items,
}) => {
	const allCategoriesLink = usePageLink(SpecialPageType.career)!
	const careerCategoryLink = (category: null | string = null) => ({
		...(category === null
			? allCategoriesLink
			: getLinkWithQuery(allCategoriesLink, { category })),
		scroll: false,
	})

	return (
		<div className="linkBoxes">
			{title && (
				<div className="linkBoxes-title">
					<BarelyVisibleTitle level={2}>{title}</BarelyVisibleTitle>
				</div>
			)}
			<div className="linkBoxes-items">
				{items.map((item, i) => (
					<div key={i} className="linkBoxes-item">
						<CardBox
							FlexiLinkProps={
								item.buttonText && item.linkType
									? item.linkType === ContentBlockColumnListItemLinkType.anchor
										? {
												nextLink: careerCategoryLink(item.careerCategory?.id),
										  }
										: (item.buttonLink as FlexiLinkProps)
									: undefined
							}
							hrefText={item.buttonText || undefined}
							heroImage={item.image}
							title={item.primaryText}
							content={item.html || ''}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
