import { ContentFragment } from './fragments'
import striptags from 'striptags'

export enum ContentBlockType {
	text = 'text',
	leadText = 'lead_text',
	image = 'image',
	textWithImage = 'image_text',
	html = 'html',
	gallery = 'image_list',
	productList = 'product_list',
	documentList = 'document_list',
	articleList = 'article_list',
	categoryList = 'category_list',
	highlightedBox = 'highlighted_box',
	contactForm = 'contact_form',
	videoList = 'video_listing',
	videoArticle = 'video_article',
	textColumns = 'text_columns',
	numberedColumns = 'numbered_columns',
	linkBoxes = 'link_boxes',
	linkSlider = 'link_slider',
	homepageTilesGrid = 'homepage_tiles',
	homepageCategorySwiper = 'category_slider',
	homepageTopCategories = 'homepage_top_categories',
	homepageNews = 'homepage_news',
	homepageArticles = 'homepage_articles',
	homepageInteractiveHero = 'banner',
}


export const sanitizeWysiwyg = (html: string) => {
	if(!html)
	{
		return html;
	}

	html = html.split('h1>').join('h2>');
	html = html.split('h1 >').join('h2>');
	return html;
}


const truncate = (str: string, n: number, useWordBoundary: boolean ) => {
	if (str.length <= n) { return str; }

	const subString = str.slice(0, n-1); // the original check
	return (useWordBoundary 
		? subString.slice(0, subString.lastIndexOf(" ")) 
		: subString) + "&hellip;";
};

export const contentToPlainText = (content?: ContentFragment, truncateTo?: number) => {
	if (!content) {
		return ''
	}

	const result = content.blocks
		.map((block) => {
			switch (block.type) {
				case ContentBlockType.leadText:
				case ContentBlockType.text:
				case ContentBlockType.textWithImage:
				case ContentBlockType.highlightedBox:
					return striptags(block.html ?? '')
				case ContentBlockType.html:
					return striptags(block.primaryText || '')
				case ContentBlockType.textColumns:
				case ContentBlockType.linkBoxes:
				case ContentBlockType.linkSlider:
				case ContentBlockType.numberedColumns:
					return (
						(block.primaryText || '') +
						' ' +
						block.columnList?.items
							.map(
								(item) =>
									(item.primaryText || '') +
									' ' +
									striptags(item.html ?? ''),
							)
							.join(' ')
					)
			}
		})
		.join(' ')
		.trim()

	if(truncateTo)
	{
		return truncate(result, truncateTo, true)
	}

	return result
}
