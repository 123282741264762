import dynamic from 'next/dynamic'
import React from 'react'
import { assertNever } from '../src/utils/assertNever'
import SvgFacebook from './icons/SvgFacebook'
import SvgLinkedin from './icons/SvgLinkedin'
import SvgPinterest from './icons/SvgPinterest'
import SvgShare from './icons/SvgShare'

interface ShareData {
	title: string
	url: string
}

export interface ShareProps {
	data: ShareData
}

const ShareTarget: React.FunctionComponent<{
	type: 'facebook' | 'linkedin' | 'pinterest'
	label: string
	data: ShareData
}> = ({ label, data, type, children }) => (
	<li className="share-target">
		<a
			href={
				type === 'facebook'
					? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
							data.url,
					  )}`
					: type === 'linkedin'
					? `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
							data.url,
					  )}&title=${encodeURIComponent(data.title)}`
					: type === 'pinterest'
					? `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
							data.url,
					  )}&description=${encodeURIComponent(data.title)}`
					: assertNever(type)
			}
			target="_blank"
			rel="noopener noreferrer"
			className="share-target-in"
			aria-label={label}
		>
			{children}
		</a>
	</li>
)

const NativeShareTarget: React.FunctionComponent<{
	data: ShareData
}> = ({ data }) => {
	const onClick = React.useCallback(() => {
		navigator.share(data)
	}, [data])

	return (
		<li className="share-target is-native">
			<button
				type="button"
				className="share-target-in"
				aria-label="Sdílet"
				onClick={onClick}
			>
				<SvgShare className="share-icon share-icon-native" />
			</button>
		</li>
	)
}

const ShareSSR: React.FunctionComponent<ShareProps> = ({ data }) => {
	const isNativeShareSupported =
		'navigator' in globalThis && 'share' in navigator

	return (
		<section className="share">
			<h5 className="share-title">Sdílet dál:</h5>
			<ul className="share-targets">
				{isNativeShareSupported ? (
					<NativeShareTarget data={data} />
				) : (
					<>
						<ShareTarget
							type="facebook"
							data={data}
							label="Sdílet na Facebook"
						>
							<SvgFacebook className="share-icon share-icon-facebook" />
						</ShareTarget>
						<ShareTarget
							type="linkedin"
							data={data}
							label="Sdílet na LinkedIn"
						>
							<SvgLinkedin className="share-icon share-icon-linkedin" />
						</ShareTarget>
						<ShareTarget
							type="pinterest"
							data={data}
							label="Sdílet na Pinterest"
						>
							<SvgPinterest className="share-icon share-icon-pinterest" />
						</ShareTarget>
					</>
				)}
			</ul>
		</section>
	)
}

export const Share = dynamic(async () => ShareSSR, { ssr: false })
