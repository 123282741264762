import React from 'react'
import SvgMascotBox from './icons/SvgMascotBox'

export type HomepageTilesGridItem = React.ReactNode

export interface HomepageTilesGrid {
	items: [
		HomepageTilesGridItem,
		HomepageTilesGridItem,
		HomepageTilesGridItem,
		HomepageTilesGridItem,
		HomepageTilesGridItem,
	]
}

export const HomepageTilesGrid: React.FunctionComponent<HomepageTilesGrid> = ({
	items,
}) => {
	return (
		<div className="homepageTilesGrid">

			<div className='homepageTilesGrid-in'>
				{items.map((item, i) => (
					<div className="homepageTilesGrid-in-item" key={i}>
						{
							i === 0 && <SvgMascotBox />
						}
						<div className="homepageTilesGrid-in-item-in">{item}</div>
					</div>
				))}
			</div>
		</div>
	)
}
