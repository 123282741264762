import * as React from 'react'
import { useCallback, useState } from 'react'
import {
	SubmitHandler,
	UnpackNestedValue,
	useForm,
	UseFormMethods,
} from 'react-hook-form'
import { ApiFetchResponse } from '../api/helpers'

export interface UseSubmitFormArgs<Values> {
	onSend?: () => void
	send: (data: UnpackNestedValue<Values>) => Promise<ApiFetchResponse<null>>
}

export interface UseSubmitForm {
	register: UseFormMethods['register']
	setValue: UseFormMethods['setValue']
	getValues: UseFormMethods['getValues']
	sent: boolean
	error: string
	submit: (e?: React.BaseSyntheticEvent) => Promise<void>
}

export const useSubmitForm = <Values>({
	onSend,
	send,
}: UseSubmitFormArgs<Values>): UseSubmitForm => {
	const { handleSubmit, register, setValue, getValues } = useForm<Values>()
	const [sent, setSent] = useState(false)
	const [error, setError] = useState('')

	const submitForm: SubmitHandler<Values> = useCallback(
		async (data) => {
			setSent(false)
			setError('')
			const responseData = await send(data)
			if (responseData.ok) {
				setError('')
				setSent(true)
				onSend && onSend()
			} else {
				setError(responseData.error)
			}
		},
		[onSend, send],
	)
	const submit = handleSubmit(submitForm)

	return {
		register,
		sent,
		error,
		submit,
		getValues,
		setValue,
	}
}
