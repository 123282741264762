import * as React from 'react'

function SvgMascotBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className='mascot' id="Vrstva_1" data-name="Vrstva 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284.07 238.98">
      <g>
    <polygon points="147.01 195.36 94.19 195.36 74.06 215.5 74.06 236.21 126.87 236.21 147.01 216.07 147.01 195.36" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polyline points="74.08 215.52 126.84 215.52 126.84 236.18" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="126.84" y1="215.52" x2="147.01" y2="195.36" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polygon points="147.01 195.36 94.19 195.36 74.06 215.5 74.06 236.21 126.87 236.21 147.01 216.07 147.01 195.36" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polyline points="74.08 215.52 126.84 215.52 126.84 236.18" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="126.84" y1="215.52" x2="147.01" y2="195.36" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
</g>
<g>
    <polygon points="239.4 195.33 186.58 195.33 166.45 215.47 166.45 236.18 219.26 236.18 239.4 216.04 239.4 195.33" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polyline points="166.47 215.49 219.23 215.49 219.23 236.16" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="219.23" y1="215.49" x2="239.4" y2="195.33" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polygon points="239.4 195.33 186.58 195.33 166.45 215.47 166.45 236.18 219.26 236.18 239.4 216.04 239.4 195.33" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polyline points="166.47 215.49 219.23 215.49 219.23 236.16" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="219.23" y1="215.49" x2="239.4" y2="195.33" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
</g>
<g>
    <polygon points="150.88 2.93 110.47 30.79 91.63 133.19 119.49 173.6 221.9 192.44 262.31 164.58 281.15 62.18 253.29 21.76 150.88 2.93" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polygon points="131.39 16.43 90.98 44.28 72.14 146.69 100 187.1 202.4 205.94 242.82 178.08 261.65 75.68 233.79 35.26 131.39 16.43" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="261.59" y1="75.63" x2="281.11" y2="62.18" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="233.71" y1="35.2" x2="253.24" y2="21.74" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
</g>
<g>
    <line x1="184.42" y1="90" x2="156.94" y2="84.86" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polygon points="110.18 63.68 164.66 73.7 161.32 91.87 136.76 108.7 123.73 106.3 106.83 81.84 110.18 63.68" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polygon points="181.22 76.76 235.71 86.75 232.38 104.92 207.84 121.77 194.8 119.38 177.89 94.93 181.22 76.76" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="78.76" y1="68.37" x2="117.32" y2="75.47" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="225.94" y1="95.44" x2="264.49" y2="102.54" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
</g>
<g>
    <rect x="130.8" y="75.41" width="10.57" height="10.57" rx="5.28" ry="5.28" transform="translate(32.11 199.94) rotate(-79.58)" />
    <rect x="201.82" y="88.48" width="10.57" height="10.57" rx="5.28" ry="5.28" transform="translate(77.44 280.5) rotate(-79.58)" />
</g>
<polyline points="125.04 51.11 153.33 56.34 168.17 46.12" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
<line x1="199.24" y1="43.52" x2="217.77" y2="70.4" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
<g>
    <polygon points="204.42 140.81 203.92 143.51 175.83 162.88 140.16 156.32 120.79 128.22 121.29 125.52 204.42 140.81" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polygon points="134.75 127.99 204.42 140.81 203.92 143.51 195.6 149.25 142.69 139.52 134.75 127.99" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <g>
        <line x1="122.71" y1="117.63" x2="119.2" y2="136.72" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
        <line x1="205.89" y1="132.93" x2="202.38" y2="152.02" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    </g>
</g>
<line x1="134.72" y1="168.94" x2="176.41" y2="176.61" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
<g>
    <polygon points="225.39 165.28 233.3 176.76 218.05 187.27 225.83 198.55 234.99 211.84 250.21 214.64 277.25 196 280.05 180.78 261.42 153.74 246.19 150.94 225.39 165.28" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="225.81" y1="198.25" x2="247.05" y2="183.61" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="233.9" y1="209.57" x2="254.95" y2="195.07" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="233.43" y1="176.67" x2="246.71" y2="167.52" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
</g>
<g>
    <polygon points="65.84 89.13 50.92 99.42 31.22 70.84 19.94 78.61 26.15 87.63 3.85 103 22.49 130.03 37.71 132.83 68.19 111.82 70.99 96.6 65.84 89.13" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <polyline points="20.57 79.5 35.8 101.58 13.4 117.02" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
    <line x1="14.94" y1="95.42" x2="24.53" y2="109.34" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "5.53px" }} />
</g>
    </svg>
	)
}

export default SvgMascotBox
