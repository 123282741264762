import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useCareerPosition } from '../src/contexts/CareerPositionContext'
import { useConfigurator } from '../src/contexts/ConfiguratorContext'
import { useSendContactMessageForm, UseSendContactMessageFormArgs } from '../src/hooks/useSendContactMessageForm'
import { useTranslate } from '../src/hooks/useTranslate'
import { BarelyVisibleTitle } from './BarelyVisibleTitle'
import { Button } from './Button'
import {
	FileUploadInput,
	FileUploadInputState,
	FileUploadInputStatus,
} from './FileUploadInput'
import { GdprDisclaimer } from './GdprDisclaimer'
import { Input } from './Input'
import { Recaptcha } from './Recaptcha'
import { Spacer } from './Spacer'

export interface ContactFormProps {
	title?: React.ReactNode
	note?: React.ReactNode
	enableFileAttachments?: false | 'single' | 'multiple'
	requireCity?: boolean
	requireFormFields?: boolean 
	formTargetEmail? :string
}

export const ContactForm: React.FunctionComponent<ContactFormProps> = ({
	title,
	note,
	enableFileAttachments = false,
	requireCity = false,
	requireFormFields = true,
	formTargetEmail = undefined
}) => {
	const translate = useTranslate()
	const router = useRouter()
	const isProject = router.asPath.endsWith('/projekt-zdarma')
	const { isPosition: isCareerPosition, message } = useCareerPosition()
	const { isConfigurator, staticMessageContent: configuratorStaticMessageContent, subject } = useConfigurator()



	const type = isCareerPosition
	? ('career' as const)
	: isConfigurator
	? ('configurator' as const)
	: isProject
	? ('project' as const)
	: ('contact' as const);

	const staticMessageContent = (type === 'configurator') ? configuratorStaticMessageContent : undefined;

	let contactFormArgs = useMemo(
		() => ({
			type: type,
			staticMessageContent: staticMessageContent,
			providedSubject: (type === 'configurator') ? subject : undefined,
			providedEmail: formTargetEmail ?? undefined
		} as UseSendContactMessageFormArgs),
		[isCareerPosition, isProject, isConfigurator, formTargetEmail],
	)
	const { sent, error, register, submit, setValue, getValues } =
		useSendContactMessageForm(contactFormArgs)

	useEffect(() => {
		register('attachments')
	}, [register])

	const [loading, setLoading] = useState(false)
	const [buttonDisabled, setButtonDisabled] = useState(false)

	const fileInputNameIndexRef = React.useRef(0);
	const [fileInputs, setFileInputs] = useState<{key: string, url: string}[]>([{
		key: 'input-' + fileInputNameIndexRef.current,
		url: ''
	}])

	const uploadHandler = useCallback(
		(state: FileUploadInputState, key = null) => {
			setButtonDisabled(state.status === FileUploadInputStatus.processing || (enableFileAttachments === 'multiple' && requireFormFields && fileInputs.filter(v => v.key.length > 0).length === 0))
			if (state.status === FileUploadInputStatus.success) {
				if (key === null) {
					setValue('attachments', [state.publicUrl])
				}
				else 
				{
					const inputs = fileInputs.map(v => {
						if(v.key === key) {
							return { key: v.key, url: state.publicUrl }
						}

						return v;
					});

					setFileInputs(inputs);
					setValue('attachments', inputs.filter(v => v.url.trim().length > 0).map(v => v.url));
				}
			}
			if (state.status === FileUploadInputStatus.error) {
				toast.error('Došlo k chybě při nahrávání souboru')
			}
		},
		[setValue, fileInputs, setFileInputs],
	)

	React.useEffect(() => {
		if (sent) {
			toast.success('Zpráva byla odeslána.')
		}
	}, [sent])

	React.useEffect(() => {
		if (error) {
			toast.error(error)
		}
	}, [error])

	React.useEffect(() => {
		if (enableFileAttachments === 'multiple' && requireFormFields) {
			setButtonDisabled(fileInputs.filter(v => v.url.length > 0).length === 0);
		}
	}, [fileInputs]);

	React.useEffect(() => {
		if(enableFileAttachments === 'single' && requireFormFields) {
			setButtonDisabled(true);
		}
	}, []);

	return (
		<form className="contactForm" onSubmit={async (e) => {
			setLoading(true);
			await submit(e);
			setLoading(false);
		}}>
			<input type="hidden" name="validation" value={requireFormFields ? 1 : 0}></input>
			{(title || note) && (
				<div className="contactForm-header">
					{title && (
						<>
							<BarelyVisibleTitle level={2}>{title}</BarelyVisibleTitle>
							<Spacer initial={16} />
						</>
					)}
					{note && (
						<>
							<div>{note}</div>
							<Spacer initial={10} />
						</>
					)}
				</div>
			)}
			<div className="contactForm-field">
				<Input
					multiline
					label={translate('contactForm-message-label')}
					ref={register}
					name="message"
					defaultValue={message} 
				/>
			</div>
			<div className="contactForm-field">
				<Input
					label={translate('contactForm-name-label')}
					autoComplete="name"
					required={requireFormFields}
					name="name"
					ref={register}
				/>
			</div>
			<div className="contactForm-field">
				<Input
					label={translate('contactForm-email-label')}
					autoComplete="email"
					required={requireFormFields}
					name="email"
					ref={register}
				/>
			</div>
			<div className="contactForm-field">
				<Input
					label={translate('contactForm-telephone-label')}
					autoComplete="tel"
					required={requireFormFields}
					name="phone"
					ref={register}
				/>
			</div>
			{requireCity && (
				<div className="contactForm-group">
					<div className="contactForm-field">
						<Input
							label={translate('contactForm-city-label')}
							autoComplete="address-level2"
							required={requireFormFields}
							name="city"
							ref={register}
						/>
					</div>
					<div className="contactForm-field">
						<Input
							label={translate('contactForm-zip-label')}
							autoComplete="postal-code"
							required={requireFormFields}
							name="zip"
							ref={register}
						/>
					</div>
				</div>
			)}
			{enableFileAttachments === 'single' && (
				<div className="contactForm-field">
					<FileUploadInput
						label={
							isCareerPosition
								? translate('contactForm-file-cv-label')
								: translate('contactForm-file-general-label')
						}
						name={'attachments'}
						onStateChange={uploadHandler}
					/>
				</div>
			)}
			{enableFileAttachments === 'multiple' && (
				<div className="contactForm-field contactForm-field--attachments">
					
					{
						(fileInputs).map(({ key, url }) => (
							<div key={key}>
								<FileUploadInput
									showCloseButton
									label={translate('contactForm-file-general-label')}
									name={'attachments'}
									onClose={() => {
										const inputs = fileInputs.filter(v => v.key !== key);
										setFileInputs(inputs);
										setValue('attachments', inputs.filter(v => v.url.trim().length > 0).map(v => v.url));
									}}
									onStateChange={(state : FileUploadInputState) => uploadHandler(state, key)}
								/>
							</div>
						))
					}

					{
						fileInputs.filter(v => v.url.trim().length > 0).length === 0 && <>
							<p className='contactForm-error'>{translate('contactForm-needs-attachments')}</p>
						</>
					}
					<a href="" onClick={(e) => { 
						e.preventDefault(); 
						fileInputNameIndexRef.current = fileInputNameIndexRef.current + 1; 
						setFileInputs([...fileInputs, { key: 'input-' + fileInputNameIndexRef.current, url : ''}]);
					}}> {translate('contactForm-add-attachment')}</a>
				</div>
			)}
			<div className="contactForm-field">
				<GdprDisclaimer />
			</div>
			<div className="contactForm-recaptcha">
				<Recaptcha register={register} setValue={setValue} />
			</div>
			<div className="contactForm-submit">
				<Button type="submit" block disabled={buttonDisabled} loading={loading}>
					{translate('contactForm-submit-label')}
				</Button>
			</div>
		</form>
	)
}
